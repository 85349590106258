import {
  CATEGORIES_LOAD_FAIL,
  CATEGORIES_LOAD_REQUEST,
  CATEGORIES_LOAD_SUCCESS,
  CATEGORIES_SAVE_FAIL,
  CATEGORIES_SAVE_REQUEST,
  CATEGORIES_SAVE_SUCCESS,
  PARAMS_LOAD_FAIL,
  PARAMS_LOAD_REQUEST,
  PARAMS_LOAD_SUCCESS,
  PARAMS_SAVE_FAIL,
  PARAMS_SAVE_REQUEST,
  PARAMS_SAVE_SUCCESS,
  PARAMS_SET_DISCIPLINE,
} from "../constants/paramConstants";
import { baseDocTypes } from "../utils/config";

export const paramReducer = (
  state = {
    loading: false,
    error: undefined,
    discipline: undefined,
    parameters: [],
    signupTypes: [],
    locations: [],
    disciplines: [],
    groupNames: [],
    docTypes: [],
    locPrefs: [],
    locPrefsEnabled: true,
    allgroups: [],
    alllocations: [],
    alllocPrefs: [],
  },
  action
) => {
  switch (action.type) {
    case PARAMS_LOAD_REQUEST:
    case PARAMS_SAVE_REQUEST:
    case CATEGORIES_LOAD_REQUEST:
    case CATEGORIES_SAVE_REQUEST:
      return { ...state, error: undefined, loading: true };
    case PARAMS_SET_DISCIPLINE:
      if (action.payload) {
        localStorage.setItem("discId", action.payload);
      } else {
        localStorage.removeItem("discId");
      }
      return { ...state, discipline: action.payload };
    case PARAMS_LOAD_SUCCESS:
    case PARAMS_SAVE_SUCCESS:
      const lockey =
        state.discipline !== undefined
          ? `LOCATIONS_${state.discipline.toUpperCase()}`
          : "LOCATIONS";
      const groupkey =
        state.discipline !== undefined
          ? `GROUPS_${state.discipline.toUpperCase()}`
          : "GROUPS";
      const docskey =
        state.discipline !== undefined
          ? `DOCTYPES_${state.discipline.toUpperCase()}`
          : "DOCTYPES";
      const locprefkey =
        state.discipline !== undefined
          ? `LOCPREFS_${state.discipline.toUpperCase()}`
          : "LOCPREFS";

      const sigupParam = action.payload.find((p) => p.name === "SIGTYPES");
      let signupTypes = [];
      if (sigupParam) {
        signupTypes = sigupParam.value.split(",");
      }

      let locations = [];
      const locParam = action.payload.find((p) => p.name === lockey);
      if (locParam && locParam.value !== "") {
        locations = locParam.value.split(",").map((loc) => {
          const [id, name] = loc.split(":");
          return { id, name };
        });
      }

      let groupNames = [];
      const groupParam = action.payload.find((p) => p.name === groupkey);
      if (groupParam && groupParam.value !== "") {
        groupParam.value.split(",").forEach((g) => {
          const [id, name, location] = g.split(":");
          groupNames[id] = { name, location };
        });
      }

      let docTypes = [...baseDocTypes];
      const docsParam = action.payload.find((p) => p.name === docskey);
      if (docsParam && docsParam.value !== "") {
        docsParam.value.split(",").forEach((doc) => {
          const [key, label, filename, info] = doc.split(":");
          docTypes.push({ key, label, filename, info, edit: true });
        });
      }

      let locPrefs = [];
      let locPrefsEnabled = true;
      const locPrefsParam = action.payload.find((p) => p.name === locprefkey);
      if (locPrefsParam && locPrefsParam.value !== "") {
        let lp = locPrefsParam.value;
        if (lp.startsWith("D,")) {
          lp = lp.slice(2);
          locPrefsEnabled = false;
        }
        locPrefs = lp.split(",").map((locpref) => {
          const [id, name, category] = locpref.split("$");
          return { id, name, category };
        });
      }

      let allgroups = [];
      action.payload.forEach((par) => {
        if (par.name.startsWith("GROUPS_")) {
          par.value.split(",").forEach((g) => {
            const [id, name, location] = g.split(":");
            if (id !== "") allgroups[id] = { name, location };
          });
        }
      });

      let alllocations = [];
      action.payload.forEach((par) => {
        if (par.name.startsWith("LOCATIONS_")) {
          par.value.split(",").forEach((l) => {
            const [id, name] = l.split(":");
            if (id !== "") alllocations[id] = { name };
          });
        }
      });

      let alllocPrefs = [];
      action.payload.forEach((par) => {
        if (par.name.startsWith("LOCPREFS_")) {
          const disc = par.name.replace("LOCPREFS_", "").toUpperCase();
          par.value.split(",").forEach((l) => {
            const [id, name, category] = l.split("$");
            if (id !== "") alllocPrefs[`${disc}_${id}`] = { name, category };
          });
        }
      });

      let disciplines = [];
      const discParameter = action.payload.find(
        (p) => p.name === "DISCIPLINES"
      );
      if (discParameter && discParameter.value !== "") {
        disciplines = discParameter.value.split(",").map((disc) => {
          const [id, name, prefix, email, banner, infodlg_str] =
            disc.split(":");
          const infodlg =
            infodlg_str !== undefined ? infodlg_str.split("$") : [];
          return { id, name, prefix, email, banner, infodlg };
        });
      }

      return {
        ...state,
        loading: false,
        error: undefined,
        parameters: action.payload,
        disciplines,
        locations,
        groupNames,
        docTypes,
        locPrefs,
        locPrefsEnabled,
        allgroups,
        alllocations,
        alllocPrefs,
        signupTypes,
      };
    case CATEGORIES_LOAD_SUCCESS:
    case CATEGORIES_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        categories: action.payload,
      };
    case PARAMS_LOAD_FAIL:
    case PARAMS_SAVE_FAIL:
    case CATEGORIES_LOAD_FAIL:
    case CATEGORIES_SAVE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
